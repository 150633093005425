import * as React from "react";
import { useEffect } from "react";
import "./App.css";
import {
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import { Form, Row } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

export const options = {
  hover: { mode: null },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    yAxes: {
      scaleShowLabels: false,
      suggestedMin: -2,
      suggestedMax: 2,
      beginAtZero: true,
      display: false,
    },
    xAxes: {
      suggestedMin: -2,
      suggestedMax: 2,
      beginAtZero: true,
      display: false,
    },
  },
};

const App = () => {
  const [value1, setValue1] = React.useState(0);
  const [value2, setValue2] = React.useState(0);
  const [value3, setValue3] = React.useState(0);
  const [value4, setValue4] = React.useState(0);
  const [value5, setValue5] = React.useState(0);

  const [imageSelector, setImageSelector] = React.useState("neutraal");
  const [data, setData] = React.useState({
    datasets: [
      {
        label: "Positie",
        data: [{ x: 0, y: 0 }],
        backgroundColor: "white",
        pointRadius: 100,
      },
    ],
  });

  useEffect(() => {
    const data = getData();
    setData(data);
    setImagePosition(data.datasets[0].data[0].x, data.datasets[0].data[0].y);
  }, [value1, value2, value3, value4, value5]);

  const setImagePosition = (xAxis, yAxis) => {
    if (yAxis < 0 && xAxis < 0) {
      setImageSelector("rechtmatig");
    }
    if (yAxis < 0 && xAxis > 0) {
      setImageSelector("responsief");
    }
    if (yAxis > 0 && xAxis < 0) {
      setImageSelector("presterend");
    }
    if (yAxis > 0 && xAxis > 0) {
      setImageSelector("netwerkend");
    }
    if (yAxis === 0 && xAxis === 0) {
      setImageSelector("neutraal");
    }
  };

  const getData = () => {
    const result = calcFifthSlider();
    const data = {
      datasets: [
        {
          label: "Positie",
          data: [result],
          backgroundColor: "white",
          pointRadius: 50,
        },
      ],
    };
    return data;
  };

  const calcFirstSlider = () => {
    let x = 0;
    if (value1 > 0) {
      x = -1;
    } else if (value1 < 0) {
      x = 2;
    } else {
      x = 0;
    }
    return {
      x,
      y: 0,
    };
  };

  const calcSecondSlider = () => {
    const result = calcFirstSlider();
    let y = 0;
    if (value2 > 0) {
      y = 2;
    } else if (value2 < 0) {
      y = -1;
    } else {
      y = 0;
    }
    result.y = y;
    return result;
  };

  const calcThirdSlider = () => {
    const result = calcSecondSlider();
    if (value3 == 2) {
      result.x = result.x + 1;
      result.y = result.y + 1;
    } else if (value3 == 1) {
      result.x = result.x + 0.5;
      result.y = result.y + 0.5;
    } else if (value3 == -2) {
      result.x = result.x - 0.5;
      result.y = result.y - 0.5;
    }
    return result;
  };

  const calcFourthSlider = () => {
    const result = calcThirdSlider();

    if (value4 == 2) {
      result.x = result.x + 1;
    } else if (value4 == 1) {
      result.x = result.x + 0.5;
    } else if (value4 == -2) {
      result.x = result.x - 0.5;
    }
    return result;
  };

  const calcFifthSlider = () => {
    const result = calcFourthSlider();

    if (value5 == 2) {
      result.x = result.x + 1;
      result.y = result.y + 1;
    } else if (value5 == 1) {
      result.x = result.x + 0.5;
      result.y = result.y + 0.5;
    } else if (value5 == -2) {
      result.x = result.x - 0.5;
      result.y = result.y - 0.5;
    }
    return result;
  };

  const clickPresterend = () => {
    setImageSelector("presterend");
  };

  const clickNetwerkend = () => {
    setImageSelector("netwerkend");
  };

  const clickRechtmatig = () => {
    setImageSelector("rechtmatig");
  };

  const clickResponsief = () => {
    setImageSelector("responsief");
  };

  return (
    <div className="mt-4">
      <Form className="mt-4 mb-4">
        <Form.Group as={Row}>
          <div class="section-stap-1 wf-section">
            <div class="page-padding">
              <div class="container-large">
                <div class="slider-wrapper">
                  <div class="text-wrapper">
                    <p class="text-legend">
                      Invloed op het proces als Rijkswaterstaat
                    </p>
                    <div class="slider-value-wrapper align-center">
                      <div class="toelichting">
                        <p>Weinig invloed</p>
                        <p>Veel invloed</p>
                      </div>
                      <RangeSlider
                        value={value1}
                        onChange={(e) => setValue1(e.target.value)}
                        min={-2}
                        max={2}
                        variant="primary"
                      />
                    </div>
                  </div>
                  <p>
                    <strong>Uitleg:</strong> Rijkswaterstaat heeft een grote
                    invloed op het proces als zij zelf initiatiefnemer en
                    bevoegd gezag is in het projectgebied. De invloed van
                    Rijkswaterstaat op het proces is klein als zij daar alleen
                    als belanghebbende of adviseur aan tafel zit.
                  </p>
                </div>
                <div class="slider-wrapper">
                  <div class="text-wrapper">
                    <p class="text-legend">Budget en tijd</p>
                    <div class="slider-value-wrapper align-center">
                      <div class="toelichting">
                        <p>Weinig budget en tijd</p>
                        <p>Veel budget en tijd</p>
                      </div>
                      <RangeSlider
                        value={value2}
                        onChange={(e) => setValue2(e.target.value)}
                        min={-2}
                        max={2}
                      />
                    </div>
                  </div>
                  <p>
                    <strong>Uitleg:</strong> Een groot participatiebudget en/of
                    een ruime aanlooptijd geven veel ruimte voor participatie.
                    Deze (financiële) ruimte kan ook gezocht worden door middel
                    van samenwerkingen met stakeholders. Weinig budget of tijd
                    zorgt over het algemeen voor minder participatieruimte. Het
                    vraagt creativiteit van de HID of omgevingsmanager om de
                    belanghebbenden op de juiste manier mee te nemen.
                  </p>
                </div>
                <div class="slider-wrapper">
                  <div class="text-wrapper">
                    <p class="text-legend">Complexiteit opgave</p>
                    <div class="slider-value-wrapper align-center">
                      <div class="toelichting">
                        <p>Lage complexiteit</p>
                        <p>Hoge complexiteit</p>
                      </div>
                      <RangeSlider
                        value={value3}
                        onChange={(e) => setValue3(e.target.value)}
                        min={-2}
                        max={2}
                      />
                    </div>
                  </div>
                  <p>
                    <strong>Uitleg:</strong> De hoge complexiteit van een
                    omgeving wordt veroorzaakt door veel stakeholders met
                    tegengestelde belangen; een gebied waar veel verschillende
                    opgaves bij elkaar komen; de cultuur of geschiedenis in de
                    regio kan een opgave complexer maken. De complexiteit kan
                    lager worden als verschillende opgaves juist goed op elkaar
                    aansluiten en de belangen op één lijn liggen.
                  </p>
                </div>
                <div class="slider-wrapper">
                  <div class="text-wrapper">
                    <p class="text-legend">Participatieruimte</p>
                    <div class="slider-value-wrapper align-center">
                      <div class="toelichting">
                        <p>Weinig participatieruime</p>
                        <p>Veel participatieruime</p>
                      </div>
                      <RangeSlider
                        value={value4}
                        onChange={(e) => setValue4(e.target.value)}
                        min={-2}
                        max={2}
                      />
                    </div>
                  </div>
                  <p>
                    <strong>Uitleg:</strong> Er is veel participatieruimte in
                    een project als je vroeg in het proces begint met het
                    betrekken van de juiste betrokkenen. Ook helpt het als de
                    opdracht breed geformuleerd is. Er is weinig
                    participatieruimte als er al een groot deel van de plannen
                    is vastgelegd. Dit kan doordat er pas laat in het proces
                    stakeholders worden betrokken, of doordat de opdracht heel
                    smal geformuleerd is.
                  </p>
                </div>
                <div class="slider-wrapper">
                  <div class="text-wrapper">
                    <p class="text-legend">Impact en gevoeligheid</p>
                    <div class="slider-value-wrapper align-center">
                      <div class="toelichting">
                        <p>Lage impact en gevoeligheid</p>
                        <p>Hoge impact en gevoeligheid</p>
                      </div>
                      <RangeSlider
                        value={value5}
                        onChange={(e) => setValue5(e.target.value)}
                        min={-2}
                        max={2}
                      />
                    </div>
                  </div>
                  <p>
                    <strong>Uitleg:</strong> Een project heeft een hoge impact
                    of gevoeligheid doordat het onderwerp politiek gevoelig
                    ligt; de opgave een grote impact heeft op de fysieke
                    leefomgeving van omwonenden en burgers of het project een
                    lange geschiedenis heeft. Als dit niet aan de orde is en de
                    omgeving juist een positieve voorgeschiedenis kent, ligt de
                    impact en gevoeligheid laag.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Form.Group>
      </Form>
      <div class="section-stap-2 wf-section">
        <div class="page-padding">
          <div class="container-large">
            <div class="hero-grid">
              <div class="card margin-top transparant">
                <div class="subtitle">Stap 2</div>
                <h1 class="heading">Bekijk de sturingsstijl</h1>
                <div class="text-size-small">
                  Op basis van de vijf schuifjes komt de witte stip op één van
                  de vier NSOB sturingsstijlen uit: een presterende,
                  netwerkende, rechtmatige of responsieve overheid. Dit is de
                  sturingsstijl die het best past bij jouw opgave. Soms eindig
                  je wat meer in het midden en zit je dus een beetje tussen twee
                  sturingsstijlen in voor deze opgave.
                </div>
              </div>
              <div className="chartBG">
                <Scatter options={options} data={data} />
              </div>
            </div>
          </div>
        </div>
        <img src={"./images/Ringen.png"} alt="" class="image-absolute" />
      </div>
      <div class="section-stap-3 wf-section">
        <div class="page-padding">
          <div class="container-large">
            <div class="card text-align-center">
              <div class="subtitle">Stap 3</div>
              <h1 class="heading">
                Wat betekent de sturingsstijl voor mijn participatiestrategie?
              </h1>
              <div class="text-size-small">
                Selecteer hieronder de sturingsstijl passend bij jouw opgave. In
                de uitleg lees je meer over acties die je kan ondernemen en
                kansen die deze sturingsstijl biedt. Maar ook lees je wanneer er
                alarmbellen moeten gaan rinkelen. Of wat momenten zijn om te
                schitteren als Rijkswaterstaat.
              </div>
              <div class="button-grid">
                <button
                  class="button-2 w-button-presterend"
                  onClick={clickPresterend}
                >
                  Presterend
                </button>
                <a
                  class="button-2 w-button-netwerkend"
                  onClick={clickNetwerkend}
                >
                  Netwerkend
                </a>
                <a
                  class="button-2 w-button-rechtmatig"
                  onClick={clickRechtmatig}
                >
                  Rechtmatig
                </a>
                <a
                  class="button-2 w-button-responsief"
                  onClick={clickResponsief}
                >
                  Responsief
                </a>
              </div>
              <img src={"./images/stijl" + imageSelector + ".svg"} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
